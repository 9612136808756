import React from "react"
import { Container } from "react-bootstrap"

import styles from "./ServerMessage.module.scss"

const ServerMessage = ({ title, message, children }) => {
  return (
    <Container fluid as={"section"} className={styles.serverMessage}>
      <Container>
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.message}>{message}</p>
        {children}
      </Container>
    </Container>
  )
}

export default ServerMessage
